function Header() {

    if (window.location.protocol === 'httpe:') {
        window.location.href = window.location.href.replace('http:', 'https:');
    }
    
    return (
        <div className="fixed z-10 w-screen left-0 top-0 py-6 px-3 bg-slate-100 shadow-md opacity-95">
            <div className="absolute z-5 font-bold start-0 top-0 py-1 w-full text-center bg-blue-200">
                🥇 <a href="https://massamon.com/" target="blank_" className="text-blue-800 animate-pulse">MASSAMON NFT: WIN UP TO 2,000 MAS</a>
            </div>
            <div className="container mx-auto">
                <h1 className="text-2xl font-bold flex mt-7">
                    <img alt="logo" style={{ width: 50 }} src="/logo192.png" className="rounded animate-pulse shadow" />
                    <a href="/" className="pt-3 ms-2">EVENTS.MASSA.GA</a>
                </h1>
            </div>
        </div>
    );
}

export default Header;
