import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CHAIN_ID, ClientFactory, DefaultProviderUrls } from "@massalabs/massa-web3";
import { formatTimeAgo } from '../functions'
import Header from '../components/header'
import Footer from '../components/footer'
import SearchBar from '../components/searchbar'

function Operation() {
  const { address } = useParams<{ address: string }>();
  const [autoRefresh, setAutoRefresh] = useState<boolean>(true);
  const [events, setEvents] = useState<{} | null>();
  const [noEvents, setNoEvents] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  async function getEvents() {
    try {
      setError(null); setNoEvents(false);

      const providerUrl = DefaultProviderUrls.MAINNET;
      const chainId = CHAIN_ID.MainNet;
      const web3Client = await ClientFactory.createDefaultClient(providerUrl, chainId, true);

      if(!address) {
        setError("No address!");
        return;
      }
      
      const event = await web3Client.smartContracts().getFilteredScOutputEvents({
        emitter_address: null,
        start: null,
        end: null,
        original_caller_address: null,
        original_operation_id: address,
        is_final: null,
      });

      if (event.length) {
        event.reverse();
        setEvents(event);
      }
      else {
        setEvents(null);
        setNoEvents(true);
      }
    } catch(error) {
        setEvents(null);
        const errorMessage = error instanceof Error ? error.message : String(error);
        setError(errorMessage);
        setAutoRefresh(false);
    }
  }

  useEffect(() => {
    getEvents();

    const interval = setInterval(() => {
      if(autoRefresh === true)
      {
        getEvents();
      }
      else {
        clearInterval(interval);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [autoRefresh]);

  return (
    <div>
      <Header />
      <div className="container mx-auto mt-32 px-2">
        <div className="relative overflow-x-auto">
            <SearchBar />
            <div className="py-2 truncate"><span className="text-sm">Operation</span> <span className="font-bold">{address}</span></div>
            <div className="mt-2">
              {events ?
                (Object.values(events).map((event: any) => (
                  <div key={event["context"]["origin_operation_id"]} className={`${event["context"]["is_error"] ? "bg-red-50 border-red-100" : "bg-green-50 border-green-100"} border rounded p-2 my-2`}>
                    <div>Age <span className="font-bold">{formatTimeAgo(new Date("2024-01-15T11:00:00").getTime()+(((event["context"]["slot"]["period"]*16)+(event["context"]["slot"]["thread"]*0.5))*1000))}</span></div>
                    <div className="truncate">Callee <span className="font-bold">{event["context"]["call_stack"][1]}</span></div>
                    <div className="truncate">Caller <span className="font-bold">{event["context"]["call_stack"][0]}</span></div>
                    <div className="mt-2">Event data
                      <div className="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg break-all">{event["data"]}</div>
                    </div>
                  </div>
                  ))
                )
              :
                (error ?
                  <div className="font-bold text-red-500">Error: {error}</div>
                :
                  (noEvents ?
                    <div>No events!</div>
                  :
                    <div>Loading..</div>
                  )
                )
              }
            </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Operation;