import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import SmartContract from './pages/SmartContract';
import Operation from './pages/Operation';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/sc/:address" element={<SmartContract />} />
        <Route path="/op/:address" element={<Operation />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;