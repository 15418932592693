import Header from '../components/header'
import Footer from '../components/footer'
import SearchBar from '../components/searchbar'

function Home() {

  return (
    <div>
      <Header />
      <div className="container mx-auto mt-32 px-2">
        <div className="relative overflow-x-auto">
            <SearchBar />
            <div className="py-2">
              <div className="text-sm truncate mt-2">
                <div>Smart contract example</div>
                <span className="font-bold size-20">AS12qzyNBDnwqq2vYwvUMHzrtMkVp6nQGJJ3TETVKF5HCd4yymzJP</span>
              </div>
              <div className="text-sm truncate mt-2">
                <div>Operation example</div>
                <span className="font-bold size-20">O1U8c2YQ8Fm4MWyCvtThZmFkCxgLUmChH9jhLQjaYkPeFY4NBbc</span>
              </div>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  );  
}

export default Home;